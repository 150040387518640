import { Box, IconButton, Table } from '@chakra-ui/react';
import { Database, ROUTES } from 'common-ts';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '../../components/ui/menu';
import {
  faEllipsisVertical,
  faPlus,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import AddUserModal from './AddUserModal';
import { Button } from '../../components/ui/button';
import CheckboxOnChangeStateWrap from './CheckboxOnChangeStateWrap';
import ContentHeader from '../../components/ContentHeader';
import DeleteModal from '../../components/DeleteModal';
import EditUserModal from './EditUserModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '../../components/ui/link-button';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { MultiValue } from 'chakra-react-select';
import SettingsSection from '../settings/SettingsSection';
import { Skeleton } from '../../components/ui/skeleton';
import { Tooltip } from '../../components/ui/tooltip';
import TransferOwnerShipModal from './TransferOwnerShipModal';
import WorkspaceUserRoleBadge from '../../components/workspace/WorkspaceUserRoleBadge';
import { addUsersToWorkspace } from '../../utils/addUsersToWorkspace';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { toaster } from '../../components/ui/toaster';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import LoadingDialog from '@/components/LoadingModal';

function UserManagement() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceUserType = useBoundStore((state) => state.workspaceUserType);
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceInactive = useBoundStore((state) => state.workspaceInactive);

  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );
  const updateFeatureFlags = useBoundStore((state) => state.updateFeatureFlags);

  const license = useBoundStore((state) => state.workspaceLicenseType);
  const isStripeCustomer = useBoundStore((state) => state.isStripeCustomer);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState<
    Database['public']['Views']['workspace_users_admin_view']['Row'] | undefined
  >(undefined);
  const [editUserModalOpen, setEditUserModalOpen] = useState<
    Database['public']['Views']['workspace_users_admin_view']['Row'] | undefined
  >(undefined);
  const [transferOwnershipModalOpen, setTransferOwnershipModalOpen] = useState<
    Database['public']['Views']['workspace_users_admin_view']['Row'] | undefined
  >(undefined);

  const [workspaceUsers, setWorkspaceUsers] = useState<
    | Database['public']['Views']['workspace_users_admin_view']['Row'][]
    | undefined
  >(undefined);
  const [workspaceSeats, setWorkspaceSeats] = useState<number | undefined>(
    undefined
  );
  const [workspaceName, setWorkspaceName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function fetchWorkspaceInfo() {
    setLoading(true);
    const userRes = await supabase
      .from('workspace_users_admin_view')
      .select('*')
      .eq('workspace_id', workspaceId)
      .order('user_type')
      .order('email');

    const workspaceRes = await supabase
      .from('workspace')
      .select('total_seats, name')
      .eq('id', workspaceId);

    if (!userRes.data?.length || !workspaceRes.data?.[0]?.total_seats) {
      captureException(
        userRes.error ??
          workspaceRes.error ??
          'Error fetching user and workspace info but supabase did not return an error'
      );
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      setLoading(false);
      return;
    }

    setLoading(false);
    setWorkspaceSeats(workspaceRes.data[0].total_seats);
    setWorkspaceUsers(userRes.data);
    setWorkspaceName(workspaceRes.data[0].name);
  }

  useEffect(() => {
    fetchWorkspaceInfo();
  }, []);

  async function handleAddUsersToWorkspace(
    emails: MultiValue<{
      label: string;
      value: string;
    }>,
    role: 'ADMIN' | 'USER' | 'OWNER'
  ) {
    await addUsersToWorkspace(
      emails.map((val) => val.value),
      role,
      supabase,
      workspaceId,
      toaster,
      t
    );
    await fetchWorkspaceInfo();
  }

  async function handleUpdateUserInfo(
    userId: string,
    userType: Database['public']['Enums']['workspace_user_type']
  ) {
    const res = await fetchApi(supabase, '/workspace', '/update_users_role', {
      method: 'POST',
      role: userType,
      userIds: [userId],
      workspaceId,
    });

    if (!res.success) {
      toaster.create({
        type: 'error',
        title:
          res.status < 500
            ? t('general.unauthorizedError')
            : t('general.reloadError'),
      });
    }

    fetchWorkspaceInfo();
    updateExtendedBuckets();
  }

  async function handleRemoveUser(userId: string) {
    setIsDeleting(true);
    try {
      const res = await fetchApi(supabase, '/workspace', '/remove_user', {
        method: 'POST',
        userId,
        workspaceId,
      });

      if (!res.success) {
        toaster.create({
          type: 'error',
          title:
            res.status < 500
              ? t('general.unauthorizedError')
              : t('general.reloadError'),
        });
      }

      await fetchWorkspaceInfo();
    } finally {
      setIsDeleting(false);
    }
  }

  async function handleTransferOwnership(userId: string | undefined) {
    if (!userId) {
      toaster.create({
        type: 'error',
        title: t('general.reloadError'),
      });
      return;
    }

    const res = await fetchApi(supabase, '/workspace', '/transfer_ownership', {
      method: 'POST',
      newOwnerId: userId,
      workspaceId,
    });

    if (!res.success) {
      toaster.create({
        type: 'error',
        title:
          res.status < 500
            ? t('general.unauthorizedError')
            : t('general.reloadError'),
      });
    }

    fetchWorkspaceInfo();
  }
  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('workspaceSettings.menu.userManagement')}
          icon={faUsers}
          iconLabel={workspaceName}
          buttons={[
            isStripeCustomer ? (
              <LinkButton
                className="text-maia-text-dark font-medium"
                variant="outline"
                href={ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.buildPath({
                  workspaceId,
                })}
              >
                {t('workspaceSettings.manageLicensesButton')}
              </LinkButton>
            ) : (
              <></>
            ),
            <Button
              colorPalette="maia-accent"
              className="text-white"
              disabled={
                !!workspaceInactive || workspaceUsers?.length === workspaceSeats
              }
              onClick={() => {
                setAddUserModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              {t('workspaceSettings.addUserButton')}
            </Button>,
          ]}
        />
        <SettingsSection>
          {t('workspaceSettings.userTableTitle')}
        </SettingsSection>
        <Box
          className="border-maia-border flex-shrink-0 rounded-xl border border-solid bg-white pb-1.5"
          colorPalette={'maia-accent'}
        >
          <Table.Root borderRadius={'12px'} variant={'line'} stickyHeader>
            <Table.Header>
              <Table.Row className="text-maia-text-dark font-semibold">
                <Table.ColumnHeader borderRadius={'12px'}>
                  {t('workspaceSettings.userTable.nameHeader')}
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  {t('workspaceSettings.userTable.emailHeader')}
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  {t('workspaceSettings.userTable.typeHeader')}
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  className={license === 'BASIC' ? 'text-chakra-gray-500' : ''}
                >
                  {t('workspaceSettings.userTable.adaHeader')}
                </Table.ColumnHeader>
                <Table.ColumnHeader borderRadius={'12px'} />
              </Table.Row>
            </Table.Header>
            <Table.Body className="text-maia-text-dark">
              {loading ? (
                <Table.Row>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Skeleton />
                  </Table.Cell>
                </Table.Row>
              ) : workspaceUsers ? (
                workspaceUsers.map((user) => {
                  return (
                    <Table.Row
                      key={user.user_id}
                      className={user.disabled ? 'bg-gray-50' : ''}
                    >
                      <Table.Cell
                        borderRadius={'12px'}
                      >{`${user.first_name ?? '-'} ${
                        user.last_name ?? ''
                      }`}</Table.Cell>
                      <Table.Cell>
                        <div className="flex items-center space-x-2">
                          <span>{user.email}</span>
                          {user.disabled && (
                            <span>
                              {`(${t('workspaceSettings.userDisabledFlag')})`}
                            </span>
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <WorkspaceUserRoleBadge
                          role={
                            user.confirmed_at
                              ? user.user_type ?? 'USER'
                              : 'INVITED'
                          }
                        />
                      </Table.Cell>

                      <Table.Cell>
                        <Tooltip
                          content={t(
                            'workspaceSettings.userTable.featureUnavailable'
                          )}
                          aria-label="feature-unavailable-tooltip"
                          disabled={license !== 'BASIC'}
                        >
                          <CheckboxOnChangeStateWrap
                            disabled={license === 'BASIC'}
                            size={'md'}
                            checked={user.feat_analyze ?? false}
                            onChange={async (checked) => {
                              const { data } = await fetchApi(
                                supabase,
                                '/workspace',
                                '/update_users',
                                {
                                  users: [
                                    {
                                      id: user.user_id ?? '',
                                      featAnalyze: checked,
                                    },
                                  ],
                                  method: 'POST',
                                  workspaceId,
                                }
                              );
                              if (user.user_id === userInfo?.userId) {
                                // update feature flags if the user is updating their own feature flags
                                // so that they don't have to refresh the page to see the changes
                                updateFeatureFlags(workspaceId);
                              }
                              return data?.userIds ? checked : !checked;
                            }}
                          />
                        </Tooltip>
                      </Table.Cell>
                      <Table.Cell className="w-20 rounded-xl">
                        <MenuRoot positioning={{ placement: 'bottom-end' }}>
                          <MenuTrigger>
                            <IconButton
                              colorPalette={'maia-gray'}
                              aria-label={t(
                                'workspaceSettings.userTable.userOptionsButton'
                              )}
                              size={'sm'}
                              variant={'ghost'}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </IconButton>
                          </MenuTrigger>
                          <MenuContent className="min-w-[130px]">
                            <MenuItem
                              value="edit"
                              onClick={() => setEditUserModalOpen(user)}
                              disabled={
                                user.user_id === userInfo?.userId ||
                                user.user_type === 'OWNER' ||
                                !!user.disabled
                              }
                            >
                              {t('workspaceSettings.editUser')}
                            </MenuItem>
                            <MenuItem
                              value="remove"
                              onClick={() => setRemoveUserModalOpen(user)}
                              disabled={
                                user.user_id === userInfo?.userId ||
                                user.user_type === 'OWNER'
                              }
                            >
                              {user.confirmed_at
                                ? t('workspaceSettings.removeUser')
                                : t('workspaceSettings.cancelInvite')}
                            </MenuItem>
                            {workspaceUserType === 'OWNER' ? (
                              <Tooltip
                                content={t(
                                  'workspaceSettings.transferOwnershipTooltip'
                                )}
                                disabled={
                                  !(
                                    user.user_id === userInfo?.userId ||
                                    user.user_type === 'USER'
                                  )
                                }
                              >
                                <MenuItem
                                  value="transfer"
                                  onClick={() =>
                                    setTransferOwnershipModalOpen(user)
                                  }
                                  disabled={
                                    user.user_id === userInfo?.userId ||
                                    user.user_type === 'USER'
                                  }
                                >
                                  {t('workspaceSettings.transferOwnerShip')}
                                </MenuItem>
                              </Tooltip>
                            ) : null}
                          </MenuContent>
                        </MenuRoot>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : null}
            </Table.Body>
          </Table.Root>
        </Box>

        <div>
          {t('workspaceSettings.usedLicenses', {
            userCount: workspaceUsers?.length,
            licenseCount: workspaceSeats,
          })}
        </div>

        <AddUserModal
          isOpen={addUserModalOpen}
          alreadyPartOfWorkspaceUserMails={
            workspaceUsers?.map((user) => user.email ?? '') ?? []
          }
          onClose={() => {
            setAddUserModalOpen(false);
          }}
          onInvite={handleAddUsersToWorkspace}
        />
        <DeleteModal
          isOpen={!!removeUserModalOpen}
          onClose={() => setRemoveUserModalOpen(undefined)}
          title={t('workspaceSettings.removeUserModal.title')}
          subtitle={t('workspaceSettings.removeUserModal.removeUserWarning', {
            name: removeUserModalOpen?.first_name
              ? `${removeUserModalOpen.first_name} ${
                  removeUserModalOpen.last_name ?? ''
                }`
              : removeUserModalOpen?.email,
          })}
          onConfirm={async () => {
            if (!removeUserModalOpen?.user_id) {
              return;
            }
            setRemoveUserModalOpen(undefined);
            await handleRemoveUser(removeUserModalOpen.user_id);
          }}
        />
        <EditUserModal
          isOpen={!!editUserModalOpen}
          onClose={() => setEditUserModalOpen(undefined)}
          user={editUserModalOpen}
          onUpdate={handleUpdateUserInfo}
        />
        <TransferOwnerShipModal
          isOpen={!!transferOwnershipModalOpen}
          onClose={() => setTransferOwnershipModalOpen(undefined)}
          user={transferOwnershipModalOpen}
          onConfirm={handleTransferOwnership}
        />
        <LoadingDialog
          isOpen={isDeleting}
          title={t('workspaceSettings.removingUserModal.deletingTitle')}
          description={t(
            'workspaceSettings.removingUserModal.deletingDescription'
          )}
        />
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}

export default UserManagement;
