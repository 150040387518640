import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { StorageFileSelectionMap, decodeName } from 'common-ts';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { ExtendedBucket } from '@/@types/extendedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import ListItem from '../../../components/ListItem.js';
import SelectableCollectionFileList from './SelectableCollectionFileList.js';
import { captureException } from '@sentry/react';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { toaster } from '@/components/ui/toaster.js';
import { useBoundStore } from '@/store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { StorageSelectionStateHandlers } from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';

type CollectionNavigatorProps = {
  searchTerm: string;
  collections: ExtendedBucket[];
  selectedFileMap: StorageFileSelectionMap;
  selectedCollection: { id: string; name: string } | undefined;
  onBackClick: () => void;
  onCollectionSelect: (
    collection: { id: string; name: string } | undefined
  ) => void;
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
};

function CollectionNavigator({
  searchTerm,
  collections,
  selectedFileMap,
  selectedCollection,
  onBackClick,
  onCollectionSelect,
  onUpdateSelectedFileMap,
  storageSelectionStateHandlers,
}: CollectionNavigatorProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const [collectionFileCounts, setCollectionFileCounts] = useState<
    Map<string, number>
  >(new Map());

  useEffect(() => {
    async function fetchCollectionFiles() {
      const { data: fileInfoData, error: fileInfoError } = await supabase
        .from('storage_file_view')
        .select('bucket_id');
      if (fileInfoData) {
        const fileCountMap = new Map<string, number>();

        fileInfoData.forEach((fileInfo) => {
          // Bucket_id is not actually nullable, but the supabase typegen thinks it is, because of how postgres views work (https://dba.stackexchange.com/questions/285302/why-columns-in-views-are-always-nullable)
          const prevFileCount = fileCountMap.get(fileInfo.bucket_id!);
          if (prevFileCount !== undefined) {
            fileCountMap.set(fileInfo.bucket_id!, prevFileCount + 1);
          } else {
            fileCountMap.set(fileInfo.bucket_id!, 1);
          }
        });

        setCollectionFileCounts(fileCountMap);
      } else if (fileInfoError) {
        captureException(fileInfoError);
        toaster.create({
          title: t('chat.fileSelector.loadingFileError'),
          type: 'error',
        });
      }
    }

    fetchCollectionFiles();
  }, [collections]);

  const collectionElements = useMemo(() => {
    return collections.map((collection) => {
      const decodedName = decodeName(collection.display_name);

      return (
        <ListItem
          variant="left"
          key={collection.id}
          className={`flex-shrink-0`}
          selected={selectedCollection?.id === collection.id}
          domElementProps={{
            onClick: () => {
              onCollectionSelect({
                id: collection.id,
                name: collection.display_name,
              });
              storageSelectionStateHandlers.updateCurrentCollection({
                id: collection.id,
                name: collection.display_name,
              });
            },
          }}
        >
          <div
            className={`flex w-full items-center justify-between gap-2 truncate`}
          >
            <div className="flex min-w-0 items-center gap-2">
              <FontAwesomeIcon
                icon={faFolder}
                className="text-chakra-gray-400 text-base"
              />

              <div>{decodedName}</div>
            </div>
            <div className="text-chakra-gray-500 flex items-center gap-1">
              <div>{collectionFileCounts?.get(collection.id) ?? ''}</div>
              <FontAwesomeIcon icon={faAngleRight} className="text-xs" />
            </div>
          </div>
        </ListItem>
      );
    });
  }, [selectedCollection, collectionFileCounts]);

  return (
    <div className="border-maia-border flex h-full w-full flex-col border-b border-t">
      <div className="flex min-h-0 flex-grow">
        {/* ------------------------------------------------ Collections ------------------------------------------------ */}
        <div
          className={`border-maia-border flex min-w-72 flex-grow flex-col overflow-y-auto px-4 py-2 md:flex-grow-0 md:border-r ${selectedCollection ? 'hidden md:flex' : ''}`}
        >
          <IconButton
            className="self-start md:hidden"
            aria-label="back"
            variant={'ghost'}
            onClick={onBackClick}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          {collectionElements}
        </div>
        {/* ------------------------------------------------ Files ------------------------------------------------ */}
        <div
          className={`min-w-0 flex-grow ${!selectedCollection ? 'hidden md:block' : ''}`}
        >
          {selectedCollection?.id && (
            <SelectableCollectionFileList
              searchTerm={searchTerm}
              key={selectedCollection.id}
              className="w-full"
              selectedCollection={selectedCollection}
              totalFiles={collectionFileCounts.get(selectedCollection.id) ?? 0}
              onUpdateSelectedFileMap={onUpdateSelectedFileMap}
              selectedFileMap={selectedFileMap}
              onBackFromRootClick={() => {
                onCollectionSelect(undefined);
              }}
              storageSelectionStateHandlers={storageSelectionStateHandlers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CollectionNavigator;
