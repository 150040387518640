import {
  SharepointFileSelectionMap,
  StorageFileSelectionMap,
  decodeName,
} from 'common-ts';

import { Button } from '@/components/ui/button';
import { ExtendedBucket } from '@/@types/extendedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Separator } from '@chakra-ui/react';
import { SharePointSelectionStateHandlers } from '../fileSelector/useSharepointSelectionStateHandlers';
import SharepointLogo from '../../../components/icons/SharepointLogo';
import { StorageSelectionStateHandlers } from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';
import { Tag } from '@/components/ui/tag';
import { faFolderMinus } from '@fortawesome/pro-regular-svg-icons';
import { useBoundStore } from '@/store/useBoundStore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type SelectedFileListProps = {
  selectedStorageFileMap: StorageFileSelectionMap;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  collectionNames: Map<string, string>;
  setFileSelectorModalOpen: (isOpen: boolean) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
  sharepointSelectionStateHandlers: SharePointSelectionStateHandlers;
};

const SelectedDataTagList = ({
  selectedStorageFileMap,
  selectedSharepointFileMap,
  setFileSelectorModalOpen,
  storageSelectionStateHandlers,
  sharepointSelectionStateHandlers,
}: SelectedFileListProps) => {
  const { t } = useTranslation();
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);

  /**
   * Sets the selected collections based on the current state of the quick selections in the storageSelectionStateHandlers
   * and the selected collections in the selectedStorageFileMap.
   *
   * If all collections are selected, it adds a special entry for "All collections".
   * Otherwise, it iterates through the other special predefined collection types (personal, shared, workspace)
   * and checks if all collections of that type are selected. If they are, it adds a special entry for that type.
   *
   * For each special type, that is not all selected, it checks if any collections of that type are selected.
   * If so, it adds them to the selectedCollections list.
   *
   * Finally, it sorts the collections so that quick select options appear first.
   */
  const selectedCollections: {
    id: string;
    name: string;
    isQuickSelectOption: boolean;
  }[] = useMemo(() => {
    const tempSelectedCollections: {
      id: string;
      name: string;
      isQuickSelectOption: boolean;
    }[] = [];

    if (storageSelectionStateHandlers.isAllCollectionsSelected) {
      tempSelectedCollections.push({
        id: 'all',
        name: t('chat.fileSelector.quickSelectOptions.all'),
        isQuickSelectOption: true,
      });
      return tempSelectedCollections;
    }

    const collectionTypes = [
      {
        isSelected:
          storageSelectionStateHandlers.isAllPersonalCollectionsSelected,
        id: 'personal',
        name: t('chat.fileSelector.quickSelectOptions.personal'),
        filter: (bucket: ExtendedBucket) =>
          !bucket.is_shared_to_user && !bucket.is_public_for_workspace,
      },
      {
        isSelected:
          storageSelectionStateHandlers.isAllSharedCollectionsSelected,
        id: 'shared',
        name: t('chat.fileSelector.quickSelectOptions.shared'),
        filter: (bucket: ExtendedBucket) =>
          bucket.is_shared_to_user && !bucket.is_public_for_workspace,
      },
      {
        isSelected:
          storageSelectionStateHandlers.isAllWorkspaceCollectionsSelected,
        id: 'workspace',
        name: t('chat.fileSelector.quickSelectOptions.workspace'),
        filter: (bucket: ExtendedBucket) => bucket.is_public_for_workspace,
      },
    ];

    collectionTypes.forEach(({ isSelected, id, name, filter }) => {
      if (isSelected) {
        tempSelectedCollections.push({ id, name, isQuickSelectOption: true });
      } else {
        const availableCollections = extendedBuckets.filter(filter);
        Object.entries(selectedStorageFileMap).find(
          ([collectionId, selectionInfo]) => {
            if (selectionInfo.selected || selectionInfo.selected === null) {
              const collection = availableCollections.find(
                (bucket) => bucket.id === collectionId
              );
              if (collection) {
                tempSelectedCollections.push({
                  id: collectionId,
                  name: collection.display_name,
                  isQuickSelectOption: false,
                });
              }
            }
          }
        );
      }
    });

    // Order collections so quick select options are first
    tempSelectedCollections.sort((a, b) => {
      if (a.isQuickSelectOption && !b.isQuickSelectOption) {
        return -1;
      } else if (!a.isQuickSelectOption && b.isQuickSelectOption) {
        return 1;
      }
      return 0;
    });

    return tempSelectedCollections;
  }, [
    selectedStorageFileMap,
    storageSelectionStateHandlers.isAllCollectionsSelected,
    storageSelectionStateHandlers.isAllPersonalCollectionsSelected,
    storageSelectionStateHandlers.isAllSharedCollectionsSelected,
    storageSelectionStateHandlers.isAllWorkspaceCollectionsSelected,
  ]);

  const selectedSharepointSites: { id: string; name: string }[] =
    useMemo(() => {
      const tempSelectedSharepointSites: { id: string; name: string }[] = [];

      Object.entries(selectedSharepointFileMap).forEach(
        ([siteId, selectionInfo]) => {
          if (selectionInfo.selected || selectionInfo.selected === null) {
            tempSelectedSharepointSites.push({
              id: siteId,
              name: selectionInfo.name,
            });
          }
        }
      );

      return tempSelectedSharepointSites;
    }, [selectedSharepointFileMap]);

  const removeAllButton = (
    <Button
      display="flex"
      alignItems="center"
      className="text-maia-gray-900 items-center justify-between px-0 pr-2 font-medium"
      key="remove-all"
      variant="plain"
      onClick={() => {
        storageSelectionStateHandlers.clearSelectedFileMap();
        sharepointSelectionStateHandlers.clearSelectedFileMap();
      }}
    >
      <FontAwesomeIcon icon={faFolderMinus} className="text-maia-gray-900" />
      {t('chat.removeAllCollections')}
    </Button>
  );

  return selectedCollections.length + selectedSharepointSites.length > 0 ? (
    <>
      <Separator />
      <div className="flex w-full flex-wrap items-center py-2">
        {removeAllButton}
        {selectedCollections.map((collection) => (
          <div key={collection.id}>
            <Tag
              id={collection.id}
              className={`${collection.isQuickSelectOption ? 'font-semibold' : ''} mr-2 flex-shrink-0`}
              variant="subtle"
              size={'md'}
              rounded={'2'}
              colorPalette="maia-gray"
              onClick={() => {
                setFileSelectorModalOpen(true);
              }}
              style={{ cursor: 'pointer' }}
            >
              {`${decodeName(collection.name)}`}
            </Tag>
          </div>
        ))}
        {selectedSharepointSites.map((site) => {
          return (
            <div key={site.id}>
              <Tag
                id={site.id}
                className="mr-2 flex-shrink-0 truncate"
                variant="subtle"
                rounded={'2'}
                colorPalette="maia-gray"
                onClick={() => {
                  setFileSelectorModalOpen(true);
                }}
                style={{ cursor: 'pointer' }}
                startElement={<SharepointLogo />}
              >
                {site.name}
              </Tag>
            </div>
          );
        })}
      </div>
    </>
  ) : null;
};

export default SelectedDataTagList;
