import { DialogBackdrop } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from '@/components/ui/dialog';

export type FileSelectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileSelector: JSX.Element;
};

export const FileSelectorModal = ({
  isOpen,
  onClose,
  fileSelector,
}: FileSelectorModalProps) => {
  const { t } = useTranslation();

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      size="xl"
    >
      <DialogBackdrop />
      <DialogContent className="flex flex-col overflow-hidden max-md:m-4 max-md:h-[calc(100vh-2rem)] max-md:w-[calc(100vw-2rem)] md:h-[80vh] md:w-[80vw] md:max-w-full lg:w-[60vw]">
        <DialogBody padding={0} className="flex-1 overflow-y-auto">
          {fileSelector}
        </DialogBody>
        <DialogFooter gap={3} className="flex-shrink-0 border-t">
          <Button className="font-medium" variant="outline" onClick={onClose}>
            {t('general.doneButtons')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
