export var AvailableModelEnum;
(function (AvailableModelEnum) {
    AvailableModelEnum["GPT_4_O"] = "gpt-4-o";
    AvailableModelEnum["GPT_4_O_MINI"] = "gpt-4-o-mini";
})(AvailableModelEnum || (AvailableModelEnum = {}));
// In case of adding new experimental models, add them here and add relevant API keys and endpoints in experimentalLLMConfig
export var ExperimentalModelEnum;
(function (ExperimentalModelEnum) {
    ExperimentalModelEnum["MISTRAL_LARGE_2407"] = "Mistral-large-2407";
    ExperimentalModelEnum["META_LLAMA_3_3_70B_INSTRUCT"] = "Meta-Llama-3-3-70B-Instruct";
})(ExperimentalModelEnum || (ExperimentalModelEnum = {}));
/**
 * Check if a model is an experimental model.
 * @param model The model to check.
 * @returns True if the model is an experimental model, otherwise false.
 */
export function isExperimentalModel(model) {
    return Object.values(ExperimentalModelEnum).includes(model);
}
const availableModelForPlan = new Map([
    ['FREE', []],
    ['BASIC', [AvailableModelEnum.GPT_4_O_MINI, AvailableModelEnum.GPT_4_O]],
    [
        'PROFESSIONAL',
        [
            AvailableModelEnum.GPT_4_O_MINI,
            AvailableModelEnum.GPT_4_O,
            ExperimentalModelEnum.MISTRAL_LARGE_2407,
            ExperimentalModelEnum.META_LLAMA_3_3_70B_INSTRUCT,
        ],
    ],
    [
        'ENTERPRISE',
        [
            AvailableModelEnum.GPT_4_O_MINI,
            AvailableModelEnum.GPT_4_O,
            ExperimentalModelEnum.MISTRAL_LARGE_2407,
            ExperimentalModelEnum.META_LLAMA_3_3_70B_INSTRUCT,
        ],
    ],
]);
/**
 * Determines whether a given model is available for a specified license tier.
 * If `byPassPlan` is true, the function checks only if the model exists, ignoring the user's plan restrictions.
 *
 * @param plan - The license tier to check model availability against.
 * @param model - The model to verify availability for.
 * @param byPassPlan - If true, bypasses plan restrictions but still ensures the model exists.
 * @returns True if the model is available for the given plan or globally (if `byPassPlan` is enabled), otherwise false.
 */
export function isModelAvailableForPlan(plan, model, byPassPlan) {
    if (byPassPlan) {
        return (Object.values(AvailableModelEnum).includes(model) ||
            Object.values(ExperimentalModelEnum).includes(model));
    }
    const allowedModels = availableModelForPlan.get(plan);
    if (!allowedModels) {
        return false;
    }
    return allowedModels.includes(model);
}
/**
 * Retrieves the available models for a given license tier.
 * If `byPassPlan` is true, returns all available and experimental models, ignoring plan restrictions.
 *
 * @param plan - The license tier to retrieve available models for.
 * @param byPassPlan - If true, returns all models regardless of the user's plan.
 * @returns An array of available models for the specified plan.
 */
export function getAvailableModelsForPlan(plan, byPassPlan) {
    if (byPassPlan) {
        return [
            ...Object.values(AvailableModelEnum),
            ...Object.values(ExperimentalModelEnum),
        ];
    }
    return availableModelForPlan.get(plan) ?? [];
}
